import React, { useMemo, useState, useCallback, useEffect, useRef } from 'react'
import { Helmet } from 'react-helmet'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import InfiniteScroll from 'react-infinite-scroller'
import { debounce } from 'debounce'

import Page, { Section } from '../../shared/page-commons'
import Title from '../../shared/section-header'
import Reference, { referenceLookup } from '../../shared/reference'
import PublicationBanner from './_publication-banner'
import { screen } from '../../shared/breakpoints'
import { FilterGroup, FilterButton, useRadioToggles } from '../../shared/filter'
import { useSearch } from '../../shared/search'

const REFERENCE_FIELDS_TO_QUERY = [
  'type',
  'key',
  'authors',
  'title',
  'journal',
  'year',
  'kind',
  'organization',
]

const FILTER_KEYS = Object.keys(referenceLookup)

const ITEMS_PER_BATCH = 15

function arrayOfLength(length) {
  return new Array(length).fill(0)
}

const SectionHeading = styled.h2`
  font-weight: bold;
  font-size: 1.7rem;
  margin-top: 1.5rem;
  margin-bottom: 2rem;
  font-family: var(--font-heading);
  line-height: 1.35;
  color: var(--heading);

  @media ${screen.sm} {
    font-size: 1.8rem;
  }

  @media ${screen.md} {
    font-size: 2.125rem;
  }
`

const SearchInput = styled.input`
  -webkit-appearance: none;
  border: none;
  display: block;
  padding: 15px 20px;
  background: var(--background);
  border-radius: 2px;
  width: 100%;
  font-size: 1rem;
  color: var(--heading);
  outline-color: var(--accent-bright);
  min-width: 250px;
  margin-top: 20px;
  margin-bottom: 20px;

  ::placeholder {
    color: var(--subtle);
  }

  @media ${screen.lg} {
    width: auto;
  }
`

const TitleRow = styled.div`
  display: block;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;

  @media ${screen.lg} {
    display: flex;
  }
`

// All logic is memoized so to improve performance by preventing unnecessary recalculations.
// A delicate ballet trying to not mutate a variable that doesn't need to be.
// Is this overkill? How dare you ask that! Of course it is...
// But searching a reference has never been this satisfying.
export default function ActPapersPage(props) {
  // This is the array of all separate parsed bib-files
  const bibliographies = props.data.allFile.nodes

  // the flattened out references will be collected here
  let references = []

  // In order to improve render performance only a part of the references
  // are initially rendered. When the user comes near the end of the currently
  // rendered items scrollBatch increases so to render the next batch of references.
  // This technique is called infinite scrolling.
  const [scrollBatch, setScrollBatch] = useState(0)

  // Change data structure of references from array to object.
  // This improves lookup speed for further use.
  const referenceByKey = useMemo(() => {
    let result = {}

    for (const bibliography of bibliographies) {
      for (const reference of bibliography.childrenReference) {
        result[reference.key] = reference
        references.push(reference)
      }
    }

    return result
  }, [bibliographies, references])

  // The result of all the filtering and querying steps.
  const [queryResultKeys, setQueryResultKeys] = useState(() => {
    // Initialize with all the keys
    return Object.keys(referenceByKey)
  })

  const { query, search, indexDocument, onQueryChange } = useSearch(
    function onChange(query) {
      debouncePerformQuery({ query })
    }
  )

  const [activeFilter, toggleFilterByKey] = useRadioToggles(
    FILTER_KEYS,
    function onToggle(activeFilterKey) {
      debouncePerformQuery({ activeFilter: activeFilterKey })
    }
  )

  // Store as a ref to aggregate the query and filter but without needing to update
  // the dependencies of either the query or activeFilter that do not need to update
  // since only one of the two variables can possibly change on a new render.
  const performQueryRef = useRef()

  // Makes sure that the reference is always up-to-date with the latest query and activeFilter
  useEffect(() => {
    performQueryRef.current = function performQuery(options) {
      // Since this function is called before the new render where either
      // query or activeFilter is updated the invoker can provide the new value.
      // The other value will be the one that was set on the render when performQuery is invoked.
      const {
        // Use provided query, fallback to current query state
        // Specified in the following pattern: provided: asVariableName = fallback
        query: queryToPerform = query,
        // Use provided activeFilter, fallback to current activeFilter state
        // Specified in the following pattern: provided: asVariableName = fallback
        activeFilter: filterToUse = activeFilter,
      } = options

      search(queryToPerform).then((matchingKeys) => {
        let filteredMatchingKeys = matchingKeys

        if (filterToUse) {
          filteredMatchingKeys = matchingKeys.filter(
            (key) => referenceByKey[key].actCategory === filterToUse
          )
        }

        setQueryResultKeys(filteredMatchingKeys)
        // Reset the scroll batch to improve render speed.
        // When user scrolls to the end more will be rendered.
        setScrollBatch(0)
      })
    }
  }, [activeFilter, query, referenceByKey, search])

  // Prevent performing an expensive query on each keystroke.
  // When the user didn't type for 100ms perform the query.
  const debouncePerformQuery = useMemo(() => {
    return debounce((options = {}) => {
      // Because the actual querying happens on a web worker
      // the result is provided asynchronous.
      return performQueryRef.current(options)
    }, 100)
  }, [])

  // Filles the searchApi with items to be queried
  useEffect(() => {
    for (const key in referenceByKey) {
      let queryString = ''

      // Concatenate all the searchable fields separated with a space.
      for (const referenceField of REFERENCE_FIELDS_TO_QUERY) {
        queryString += `${referenceByKey[key][referenceField]} `
      }

      indexDocument(key, queryString)
    }
  }, [indexDocument, referenceByKey])

  // Change data structure to reflect what should be rendered.
  const resultByYear = useMemo(() => {
    let result = {}

    for (const key of queryResultKeys) {
      const { year } = referenceByKey[key]

      if (!result[year]) {
        result[year] = []
      }

      result[year].push(key)
    }

    return result
  }, [referenceByKey, queryResultKeys])

  // Sort the years in the query result in descending order.
  const sortedYears = useMemo(() => {
    return Object.keys(resultByYear)
      .map((yearAsString) => parseInt(yearAsString))
      .sort((a, b) => b - a)
      .map((yearAsNumber) => yearAsNumber.toString())
  }, [resultByYear])

  // Map continuous numbers to items grouped and sorted by year.
  const getRenderDataByIndex = useCallback(
    (index) => {
      // Keep track of the total number of references in the previously checked years.
      let visitedResults = 0

      for (const year of sortedYears) {
        if (index < visitedResults + resultByYear[year].length) {
          const nthItemInYear = index - visitedResults
          const key = resultByYear[year][nthItemInYear]

          return {
            reference: referenceByKey[key],
            nthItemInYear,
            year,
          }
        }

        visitedResults += resultByYear[year].length
      }

      throw new Error(`Reference could not be found by index ${index}`)
    },
    [referenceByKey, resultByYear, sortedYears]
  )

  let itemsToRender = (scrollBatch + 1) * ITEMS_PER_BATCH
  const canLoadMore = itemsToRender < queryResultKeys.length

  // Prevent trying to render more than the number of results.
  if (!canLoadMore) {
    itemsToRender = queryResultKeys.length
  }

  return (
    <Page bgColor="surface">
      <Helmet>
        <meta
          name="description"
          content="Scientific publications of the ACT."
        />
        <meta
          property="og:description"
          content="Scientific publications of the ACT."
        />
        <meta
          name="twitter:description"
          content="Scientific publications of the ACT."
        />
      </Helmet>

      <PublicationBanner />
      <Section>
        <TitleRow>
          <Title css="flex-grow: 1; margin: 0; margin-right: 20px;">
            Our publications ({queryResultKeys.length})
          </Title>
          <SearchInput
            type="search"
            placeholder="Search..."
            value={query}
            onChange={onQueryChange}
          />
        </TitleRow>
        <FilterGroup>
          {Object.entries(referenceLookup).map(([filterKey, filterName]) => (
            <FilterButton
              key={filterKey}
              onClick={toggleFilterByKey[filterKey]}
              active={activeFilter === filterKey}
            >
              {filterName}
            </FilterButton>
          ))}
        </FilterGroup>
        <InfiniteScroll
          loadMore={() => setScrollBatch(scrollBatch + 1)}
          hasMore={canLoadMore}
          loader={null}
        >
          {arrayOfLength(itemsToRender).map((_, index) => {
            const renderData = getRenderDataByIndex(index)
            const { reference, nthItemInYear, year } = renderData

            return (
              <React.Fragment key={reference.key}>
                {/* Render the section heading before the first item of a year. */}
                {nthItemInYear === 0 && (
                  <SectionHeading>
                    {year} ({resultByYear[year].length})
                  </SectionHeading>
                )}
                <Reference reference={reference} />
              </React.Fragment>
            )
          })}
        </InfiniteScroll>
      </Section>
    </Page>
  )
}

export const query = graphql`
  query ActPapersPage {
    allFile(
      filter: {
        absolutePath: { glob: "**/src/bibliographies/paper/**" }
        extension: { eq: "bib" }
      }
    ) {
      nodes {
        childrenReference {
          actCategory
          authors
          booktitle
          editor
          institution
          journal
          key
          kind
          month
          number
          organization
          pages
          publisher
          quarter
          rawBibtex
          title
          type
          url
          volume
          year
        }
      }
    }
  }
`
